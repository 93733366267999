import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dbb18a12 = () => interopDefault(import('../pages/app/index.vue' /* webpackChunkName: "pages/app/index" */))
const _66f4e649 = () => interopDefault(import('../pages/cities/index.vue' /* webpackChunkName: "pages/cities/index" */))
const _821f72e2 = () => interopDefault(import('../pages/contacto/index.vue' /* webpackChunkName: "pages/contacto/index" */))
const _179c26e5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6bf46083 = () => interopDefault(import('../pages/finish_purchase/index.vue' /* webpackChunkName: "pages/finish_purchase/index" */))
const _68fc6e00 = () => interopDefault(import('../pages/nearme/index.vue' /* webpackChunkName: "pages/nearme/index" */))
const _2b7d1c22 = () => interopDefault(import('../pages/oxxo_order/index.vue' /* webpackChunkName: "pages/oxxo_order/index" */))
const _9b85c2a8 = () => interopDefault(import('../pages/oxxo_reference/index.vue' /* webpackChunkName: "pages/oxxo_reference/index" */))
const _429365e8 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _bbb7ab66 = () => interopDefault(import('../pages/spotify_auth/index.vue' /* webpackChunkName: "pages/spotify_auth/index" */))
const _47739b5d = () => interopDefault(import('../pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _06de0d04 = () => interopDefault(import('../pages/legal/license.vue' /* webpackChunkName: "pages/legal/license" */))
const _616625c8 = () => interopDefault(import('../pages/legal/privacy_policy.vue' /* webpackChunkName: "pages/legal/privacy_policy" */))
const _9d2326d0 = () => interopDefault(import('../pages/legal/terms_of_service.vue' /* webpackChunkName: "pages/legal/terms_of_service" */))
const _506630e2 = () => interopDefault(import('../pages/news/coronavirus.vue' /* webpackChunkName: "pages/news/coronavirus" */))
const _423a5cfe = () => interopDefault(import('../pages/subscription/benefits.vue' /* webpackChunkName: "pages/subscription/benefits" */))
const _2cb2eb0b = () => interopDefault(import('../pages/subscription/checkout.vue' /* webpackChunkName: "pages/subscription/checkout" */))
const _5f5a3bfa = () => interopDefault(import('../pages/subscription/confirmation.vue' /* webpackChunkName: "pages/subscription/confirmation" */))
const _1d1d4cdc = () => interopDefault(import('../pages/subscription/terms.vue' /* webpackChunkName: "pages/subscription/terms" */))
const _0558a6b3 = () => interopDefault(import('../pages/user/events/index.vue' /* webpackChunkName: "pages/user/events/index" */))
const _d59a6176 = () => interopDefault(import('../pages/user/faves/index.vue' /* webpackChunkName: "pages/user/faves/index" */))
const _27785567 = () => interopDefault(import('../pages/user/payment-methods/index.vue' /* webpackChunkName: "pages/user/payment-methods/index" */))
const _d42d6d06 = () => interopDefault(import('../pages/user/settings/index.vue' /* webpackChunkName: "pages/user/settings/index" */))
const _3c275e77 = () => interopDefault(import('../pages/user/subscription/index.vue' /* webpackChunkName: "pages/user/subscription/index" */))
const _bcb42ad8 = () => interopDefault(import('../pages/auth/pop/google/index.vue' /* webpackChunkName: "pages/auth/pop/google/index" */))
const _eadafa5c = () => interopDefault(import('../pages/user/payment-methods/subscription_payment_methods.vue' /* webpackChunkName: "pages/user/payment-methods/subscription_payment_methods" */))
const _2b1d4a2e = () => interopDefault(import('../pages/email/verification/_email/_userWebId/_token/index.vue' /* webpackChunkName: "pages/email/verification/_email/_userWebId/_token/index" */))
const _b7c4c4e2 = () => interopDefault(import('../pages/subscription/cancel/_userWebId/_subscriptionId/_token/index.vue' /* webpackChunkName: "pages/subscription/cancel/_userWebId/_subscriptionId/_token/index" */))
const _315e4ee1 = () => interopDefault(import('../pages/book/_search/index.vue' /* webpackChunkName: "pages/book/_search/index" */))
const _af973824 = () => interopDefault(import('../pages/checkout/_ticket_group_id/index.vue' /* webpackChunkName: "pages/checkout/_ticket_group_id/index" */))
const _15610fc6 = () => interopDefault(import('../pages/reset-password/_token/index.vue' /* webpackChunkName: "pages/reset-password/_token/index" */))
const _1dc1bb27 = () => interopDefault(import('../pages/results/_name/index.vue' /* webpackChunkName: "pages/results/_name/index" */))
const _09d089be = () => interopDefault(import('../pages/e/_name/_id.vue' /* webpackChunkName: "pages/e/_name/_id" */))
const _7661a842 = () => interopDefault(import('../pages/book/_search/_venue_date/_event_id/index.vue' /* webpackChunkName: "pages/book/_search/_venue_date/_event_id/index" */))
const _63b55db2 = () => interopDefault(import('../pages/cities/_parent/_category_city/_city.vue' /* webpackChunkName: "pages/cities/_parent/_category_city/_city" */))
const _38efe187 = () => interopDefault(import('../pages/get_promo/_name/_firstName/_lastName/_email/index.vue' /* webpackChunkName: "pages/get_promo/_name/_firstName/_lastName/_email/index" */))
const _4649210b = () => interopDefault(import('../pages/_parent/_category_city/_city.vue' /* webpackChunkName: "pages/_parent/_category_city/_city" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/app",
    component: _dbb18a12,
    name: "app___en___default"
  }, {
    path: "/cities",
    component: _66f4e649,
    name: "cities___en___default"
  }, {
    path: "/contacto",
    component: _821f72e2,
    name: "contacto___en___default"
  }, {
    path: "/en",
    component: _179c26e5,
    name: "index___en"
  }, {
    path: "/es",
    component: _179c26e5,
    name: "index___es"
  }, {
    path: "/finish_purchase",
    component: _6bf46083,
    name: "finish_purchase___en___default"
  }, {
    path: "/nearme",
    component: _68fc6e00,
    name: "nearme___en___default"
  }, {
    path: "/oxxo_order",
    component: _2b7d1c22,
    name: "oxxo_order___en___default"
  }, {
    path: "/oxxo_reference",
    component: _9b85c2a8,
    name: "oxxo_reference___en___default"
  }, {
    path: "/search",
    component: _429365e8,
    name: "search___en___default"
  }, {
    path: "/spotify_auth",
    component: _bbb7ab66,
    name: "spotify_auth___en___default"
  }, {
    path: "/subscription",
    component: _47739b5d,
    name: "subscription___en___default"
  }, {
    path: "/en/app",
    component: _dbb18a12,
    name: "app___en"
  }, {
    path: "/en/cities",
    component: _66f4e649,
    name: "cities___en"
  }, {
    path: "/en/contacto",
    component: _821f72e2,
    name: "contacto___en"
  }, {
    path: "/en/finish_purchase",
    component: _6bf46083,
    name: "finish_purchase___en"
  }, {
    path: "/en/nearme",
    component: _68fc6e00,
    name: "nearme___en"
  }, {
    path: "/en/oxxo_order",
    component: _2b7d1c22,
    name: "oxxo_order___en"
  }, {
    path: "/en/oxxo_reference",
    component: _9b85c2a8,
    name: "oxxo_reference___en"
  }, {
    path: "/en/search",
    component: _429365e8,
    name: "search___en"
  }, {
    path: "/en/spotify_auth",
    component: _bbb7ab66,
    name: "spotify_auth___en"
  }, {
    path: "/en/subscription",
    component: _47739b5d,
    name: "subscription___en"
  }, {
    path: "/es/app",
    component: _dbb18a12,
    name: "app___es"
  }, {
    path: "/es/cities",
    component: _66f4e649,
    name: "cities___es"
  }, {
    path: "/es/contacto",
    component: _821f72e2,
    name: "contacto___es"
  }, {
    path: "/es/finish_purchase",
    component: _6bf46083,
    name: "finish_purchase___es"
  }, {
    path: "/es/nearme",
    component: _68fc6e00,
    name: "nearme___es"
  }, {
    path: "/es/oxxo_order",
    component: _2b7d1c22,
    name: "oxxo_order___es"
  }, {
    path: "/es/oxxo_reference",
    component: _9b85c2a8,
    name: "oxxo_reference___es"
  }, {
    path: "/es/search",
    component: _429365e8,
    name: "search___es"
  }, {
    path: "/es/spotify_auth",
    component: _bbb7ab66,
    name: "spotify_auth___es"
  }, {
    path: "/es/subscription",
    component: _47739b5d,
    name: "subscription___es"
  }, {
    path: "/legal/license",
    component: _06de0d04,
    name: "legal-license___en___default"
  }, {
    path: "/legal/privacy_policy",
    component: _616625c8,
    name: "legal-privacy_policy___en___default"
  }, {
    path: "/legal/terms_of_service",
    component: _9d2326d0,
    name: "legal-terms_of_service___en___default"
  }, {
    path: "/news/coronavirus",
    component: _506630e2,
    name: "news-coronavirus___en___default"
  }, {
    path: "/subscription/benefits",
    component: _423a5cfe,
    name: "subscription-benefits___en___default"
  }, {
    path: "/subscription/checkout",
    component: _2cb2eb0b,
    name: "subscription-checkout___en___default"
  }, {
    path: "/subscription/confirmation",
    component: _5f5a3bfa,
    name: "subscription-confirmation___en___default"
  }, {
    path: "/subscription/terms",
    component: _1d1d4cdc,
    name: "subscription-terms___en___default"
  }, {
    path: "/user/events",
    component: _0558a6b3,
    name: "user-events___en___default"
  }, {
    path: "/user/faves",
    component: _d59a6176,
    name: "user-faves___en___default"
  }, {
    path: "/user/payment-methods",
    component: _27785567,
    name: "user-payment-methods___en___default"
  }, {
    path: "/user/settings",
    component: _d42d6d06,
    name: "user-settings___en___default"
  }, {
    path: "/user/subscription",
    component: _3c275e77,
    name: "user-subscription___en___default"
  }, {
    path: "/auth/pop/google",
    component: _bcb42ad8,
    name: "auth-pop-google___en___default"
  }, {
    path: "/en/legal/license",
    component: _06de0d04,
    name: "legal-license___en"
  }, {
    path: "/en/legal/privacy_policy",
    component: _616625c8,
    name: "legal-privacy_policy___en"
  }, {
    path: "/en/legal/terms_of_service",
    component: _9d2326d0,
    name: "legal-terms_of_service___en"
  }, {
    path: "/en/news/coronavirus",
    component: _506630e2,
    name: "news-coronavirus___en"
  }, {
    path: "/en/subscription/benefits",
    component: _423a5cfe,
    name: "subscription-benefits___en"
  }, {
    path: "/en/subscription/checkout",
    component: _2cb2eb0b,
    name: "subscription-checkout___en"
  }, {
    path: "/en/subscription/confirmation",
    component: _5f5a3bfa,
    name: "subscription-confirmation___en"
  }, {
    path: "/en/subscription/terms",
    component: _1d1d4cdc,
    name: "subscription-terms___en"
  }, {
    path: "/en/user/events",
    component: _0558a6b3,
    name: "user-events___en"
  }, {
    path: "/en/user/faves",
    component: _d59a6176,
    name: "user-faves___en"
  }, {
    path: "/en/user/payment-methods",
    component: _27785567,
    name: "user-payment-methods___en"
  }, {
    path: "/en/user/settings",
    component: _d42d6d06,
    name: "user-settings___en"
  }, {
    path: "/en/user/subscription",
    component: _3c275e77,
    name: "user-subscription___en"
  }, {
    path: "/es/legal/license",
    component: _06de0d04,
    name: "legal-license___es"
  }, {
    path: "/es/legal/privacy_policy",
    component: _616625c8,
    name: "legal-privacy_policy___es"
  }, {
    path: "/es/legal/terms_of_service",
    component: _9d2326d0,
    name: "legal-terms_of_service___es"
  }, {
    path: "/es/news/coronavirus",
    component: _506630e2,
    name: "news-coronavirus___es"
  }, {
    path: "/es/subscription/benefits",
    component: _423a5cfe,
    name: "subscription-benefits___es"
  }, {
    path: "/es/subscription/checkout",
    component: _2cb2eb0b,
    name: "subscription-checkout___es"
  }, {
    path: "/es/subscription/confirmation",
    component: _5f5a3bfa,
    name: "subscription-confirmation___es"
  }, {
    path: "/es/subscription/terms",
    component: _1d1d4cdc,
    name: "subscription-terms___es"
  }, {
    path: "/es/user/events",
    component: _0558a6b3,
    name: "user-events___es"
  }, {
    path: "/es/user/faves",
    component: _d59a6176,
    name: "user-faves___es"
  }, {
    path: "/es/user/payment-methods",
    component: _27785567,
    name: "user-payment-methods___es"
  }, {
    path: "/es/user/settings",
    component: _d42d6d06,
    name: "user-settings___es"
  }, {
    path: "/es/user/subscription",
    component: _3c275e77,
    name: "user-subscription___es"
  }, {
    path: "/user/payment-methods/subscription_payment_methods",
    component: _eadafa5c,
    name: "user-payment-methods-subscription_payment_methods___en___default"
  }, {
    path: "/en/auth/pop/google",
    component: _bcb42ad8,
    name: "auth-pop-google___en"
  }, {
    path: "/en/user/payment-methods/subscription_payment_methods",
    component: _eadafa5c,
    name: "user-payment-methods-subscription_payment_methods___en"
  }, {
    path: "/es/auth/pop/google",
    component: _bcb42ad8,
    name: "auth-pop-google___es"
  }, {
    path: "/es/user/payment-methods/subscription_payment_methods",
    component: _eadafa5c,
    name: "user-payment-methods-subscription_payment_methods___es"
  }, {
    path: "/",
    component: _179c26e5,
    name: "index___en___default"
  }, {
    path: "/en/email/verification/:email?/:userWebId?/:token",
    component: _2b1d4a2e,
    name: "email-verification-email-userWebId-token___en"
  }, {
    path: "/en/subscription/cancel/:userWebId?/:subscriptionId?/:token",
    component: _b7c4c4e2,
    name: "subscription-cancel-userWebId-subscriptionId-token___en"
  }, {
    path: "/es/email/verification/:email?/:userWebId?/:token",
    component: _2b1d4a2e,
    name: "email-verification-email-userWebId-token___es"
  }, {
    path: "/es/subscription/cancel/:userWebId?/:subscriptionId?/:token",
    component: _b7c4c4e2,
    name: "subscription-cancel-userWebId-subscriptionId-token___es"
  }, {
    path: "/en/book/:search",
    component: _315e4ee1,
    name: "book-search___en"
  }, {
    path: "/en/checkout/:ticket_group_id",
    component: _af973824,
    name: "checkout-ticket_group_id___en"
  }, {
    path: "/en/reset-password/:token",
    component: _15610fc6,
    name: "reset-password-token___en"
  }, {
    path: "/en/results/:name",
    component: _1dc1bb27,
    name: "results-name___en"
  }, {
    path: "/es/book/:search",
    component: _315e4ee1,
    name: "book-search___es"
  }, {
    path: "/es/checkout/:ticket_group_id",
    component: _af973824,
    name: "checkout-ticket_group_id___es"
  }, {
    path: "/es/reset-password/:token",
    component: _15610fc6,
    name: "reset-password-token___es"
  }, {
    path: "/es/results/:name",
    component: _1dc1bb27,
    name: "results-name___es"
  }, {
    path: "/en/e/:name?/:id?",
    component: _09d089be,
    name: "e-name-id___en"
  }, {
    path: "/es/e/:name?/:id?",
    component: _09d089be,
    name: "e-name-id___es"
  }, {
    path: "/email/verification/:email?/:userWebId?/:token",
    component: _2b1d4a2e,
    name: "email-verification-email-userWebId-token___en___default"
  }, {
    path: "/en/book/:search?/:venue_date/:event_id",
    component: _7661a842,
    name: "book-search-venue_date-event_id___en"
  }, {
    path: "/en/cities/:parent/:category_city?/:city?",
    component: _63b55db2,
    name: "cities-parent-category_city-city___en"
  }, {
    path: "/es/book/:search?/:venue_date/:event_id",
    component: _7661a842,
    name: "book-search-venue_date-event_id___es"
  }, {
    path: "/es/cities/:parent/:category_city?/:city?",
    component: _63b55db2,
    name: "cities-parent-category_city-city___es"
  }, {
    path: "/subscription/cancel/:userWebId?/:subscriptionId?/:token",
    component: _b7c4c4e2,
    name: "subscription-cancel-userWebId-subscriptionId-token___en___default"
  }, {
    path: "/en/get_promo/:name?/:firstName?/:lastName?/:email",
    component: _38efe187,
    name: "get_promo-name-firstName-lastName-email___en"
  }, {
    path: "/es/get_promo/:name?/:firstName?/:lastName?/:email",
    component: _38efe187,
    name: "get_promo-name-firstName-lastName-email___es"
  }, {
    path: "/book/:search",
    component: _315e4ee1,
    name: "book-search___en___default"
  }, {
    path: "/checkout/:ticket_group_id",
    component: _af973824,
    name: "checkout-ticket_group_id___en___default"
  }, {
    path: "/reset-password/:token",
    component: _15610fc6,
    name: "reset-password-token___en___default"
  }, {
    path: "/results/:name",
    component: _1dc1bb27,
    name: "results-name___en___default"
  }, {
    path: "/e/:name?/:id?",
    component: _09d089be,
    name: "e-name-id___en___default"
  }, {
    path: "/book/:search?/:venue_date/:event_id",
    component: _7661a842,
    name: "book-search-venue_date-event_id___en___default"
  }, {
    path: "/cities/:parent/:category_city?/:city?",
    component: _63b55db2,
    name: "cities-parent-category_city-city___en___default"
  }, {
    path: "/en/:parent/:category_city?/:city?",
    component: _4649210b,
    name: "parent-category_city-city___en"
  }, {
    path: "/es/:parent/:category_city?/:city?",
    component: _4649210b,
    name: "parent-category_city-city___es"
  }, {
    path: "/get_promo/:name?/:firstName?/:lastName?/:email",
    component: _38efe187,
    name: "get_promo-name-firstName-lastName-email___en___default"
  }, {
    path: "/:parent/:category_city?/:city?",
    component: _4649210b,
    name: "parent-category_city-city___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
