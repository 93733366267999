export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const AdminPostForm = () => import('../../components/Admin/AdminPostForm.vue' /* webpackChunkName: "components/admin-post-form" */).then(c => wrapFunctional(c.default || c))
export const BraintreeCreditCard = () => import('../../components/Braintree/CreditCard.vue' /* webpackChunkName: "components/braintree-credit-card" */).then(c => wrapFunctional(c.default || c))
export const BraintreePayPalButton = () => import('../../components/Braintree/PayPalButton.vue' /* webpackChunkName: "components/braintree-pay-pal-button" */).then(c => wrapFunctional(c.default || c))
export const BraintreePaymentMethod = () => import('../../components/Braintree/PaymentMethod.vue' /* webpackChunkName: "components/braintree-payment-method" */).then(c => wrapFunctional(c.default || c))
export const BraintreePaymentMethodDropIn = () => import('../../components/Braintree/PaymentMethodDropIn.vue' /* webpackChunkName: "components/braintree-payment-method-drop-in" */).then(c => wrapFunctional(c.default || c))
export const BraintreeSubscriptionCancel = () => import('../../components/Braintree/SubscriptionCancel.vue' /* webpackChunkName: "components/braintree-subscription-cancel" */).then(c => wrapFunctional(c.default || c))
export const BraintreeTransactions = () => import('../../components/Braintree/Transactions.vue' /* webpackChunkName: "components/braintree-transactions" */).then(c => wrapFunctional(c.default || c))
export const ButtonsPriceButton = () => import('../../components/Buttons/PriceButton.vue' /* webpackChunkName: "components/buttons-price-button" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddCreditCard = () => import('../../components/Checkout/AddCreditCard.vue' /* webpackChunkName: "components/checkout-add-credit-card" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddPromoCode = () => import('../../components/Checkout/AddPromoCode.vue' /* webpackChunkName: "components/checkout-add-promo-code" */).then(c => wrapFunctional(c.default || c))
export const CheckoutBanner = () => import('../../components/Checkout/Banner.vue' /* webpackChunkName: "components/checkout-banner" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDiscountPanel = () => import('../../components/Checkout/DiscountPanel.vue' /* webpackChunkName: "components/checkout-discount-panel" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOrderSummary = () => import('../../components/Checkout/OrderSummary.vue' /* webpackChunkName: "components/checkout-order-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutReviewOrder = () => import('../../components/Checkout/ReviewOrder.vue' /* webpackChunkName: "components/checkout-review-order" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSectionDiscount = () => import('../../components/Checkout/SectionDiscount.vue' /* webpackChunkName: "components/checkout-section-discount" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSignedUpWithEmail = () => import('../../components/Checkout/SignedUpWithEmail.vue' /* webpackChunkName: "components/checkout-signed-up-with-email" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSnackBar = () => import('../../components/Checkout/SnackBar.vue' /* webpackChunkName: "components/checkout-snack-bar" */).then(c => wrapFunctional(c.default || c))
export const CheckoutWelcomeDiscount = () => import('../../components/Checkout/WelcomeDiscount.vue' /* webpackChunkName: "components/checkout-welcome-discount" */).then(c => wrapFunctional(c.default || c))
export const EventCardG = () => import('../../components/EventCards/EventCardG.vue' /* webpackChunkName: "components/event-card-g" */).then(c => wrapFunctional(c.default || c))
export const EventCardGNearme = () => import('../../components/EventCards/EventCardGNearme.vue' /* webpackChunkName: "components/event-card-g-nearme" */).then(c => wrapFunctional(c.default || c))
export const Divider = () => import('../../components/Divider/Divider.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c))
export const Contact = () => import('../../components/Contact/Contact.vue' /* webpackChunkName: "components/contact" */).then(c => wrapFunctional(c.default || c))
export const FacebookLoginButton = () => import('../../components/Facebook/LoginButton.vue' /* webpackChunkName: "components/facebook-login-button" */).then(c => wrapFunctional(c.default || c))
export const FacebookShareButton = () => import('../../components/Facebook/ShareButton.vue' /* webpackChunkName: "components/facebook-share-button" */).then(c => wrapFunctional(c.default || c))
export const FacebookSigninButton = () => import('../../components/Facebook/SigninButton.vue' /* webpackChunkName: "components/facebook-signin-button" */).then(c => wrapFunctional(c.default || c))
export const FollowButton = () => import('../../components/Follow/Button.vue' /* webpackChunkName: "components/follow-button" */).then(c => wrapFunctional(c.default || c))
export const FooterCategoriesMenu = () => import('../../components/Footer/CategoriesMenu.vue' /* webpackChunkName: "components/footer-categories-menu" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FormCheckBox = () => import('../../components/Form/CheckBox.vue' /* webpackChunkName: "components/form-check-box" */).then(c => wrapFunctional(c.default || c))
export const GoogleLoginButton = () => import('../../components/Google/LoginButton.vue' /* webpackChunkName: "components/google-login-button" */).then(c => wrapFunctional(c.default || c))
export const GoogleSigninButton = () => import('../../components/Google/SigninButton.vue' /* webpackChunkName: "components/google-signin-button" */).then(c => wrapFunctional(c.default || c))
export const HomeHowItWorks = () => import('../../components/Home/HowItWorks.vue' /* webpackChunkName: "components/home-how-it-works" */).then(c => wrapFunctional(c.default || c))
export const ImageSpeedPicture = () => import('../../components/Image/SpeedPicture.vue' /* webpackChunkName: "components/image-speed-picture" */).then(c => wrapFunctional(c.default || c))
export const LoadCard = () => import('../../components/Load/LoadCard.vue' /* webpackChunkName: "components/load-card" */).then(c => wrapFunctional(c.default || c))
export const LoadCardNearme = () => import('../../components/Load/LoadCardNearme.vue' /* webpackChunkName: "components/load-card-nearme" */).then(c => wrapFunctional(c.default || c))
export const LoadModal = () => import('../../components/Load/LoadModal.vue' /* webpackChunkName: "components/load-modal" */).then(c => wrapFunctional(c.default || c))
export const NavigationHeader = () => import('../../components/Navigation/Header.vue' /* webpackChunkName: "components/navigation-header" */).then(c => wrapFunctional(c.default || c))
export const NavigationHeaderNearme = () => import('../../components/Navigation/HeaderNearme.vue' /* webpackChunkName: "components/navigation-header-nearme" */).then(c => wrapFunctional(c.default || c))
export const NearmeSelectCity = () => import('../../components/Nearme/SelectCity.vue' /* webpackChunkName: "components/nearme-select-city" */).then(c => wrapFunctional(c.default || c))
export const NearmeSelectCityA = () => import('../../components/Nearme/SelectCityA.vue' /* webpackChunkName: "components/nearme-select-city-a" */).then(c => wrapFunctional(c.default || c))
export const NearmeSelectCityB = () => import('../../components/Nearme/SelectCityB.vue' /* webpackChunkName: "components/nearme-select-city-b" */).then(c => wrapFunctional(c.default || c))
export const NotificationFollowOnSpotifyModal = () => import('../../components/Notification/FollowOnSpotifyModal.vue' /* webpackChunkName: "components/notification-follow-on-spotify-modal" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/Notification/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const Observer = () => import('../../components/Observer/Observer.vue' /* webpackChunkName: "components/observer" */).then(c => wrapFunctional(c.default || c))
export const OnboardingModalsModalBaseNoModificar = () => import('../../components/OnboardingModals/ModalBaseNoModificar.vue' /* webpackChunkName: "components/onboarding-modals-modal-base-no-modificar" */).then(c => wrapFunctional(c.default || c))
export const OnboardingOxxo = () => import('../../components/OnboardingModals/OnboardingOxxo.vue' /* webpackChunkName: "components/onboarding-oxxo" */).then(c => wrapFunctional(c.default || c))
export const OnboardingModalsOxxoOnBoarding = () => import('../../components/OnboardingModals/OxxoOnBoarding.vue' /* webpackChunkName: "components/onboarding-modals-oxxo-on-boarding" */).then(c => wrapFunctional(c.default || c))
export const PepperCashModal = () => import('../../components/PepperCash/PepperCashModal.vue' /* webpackChunkName: "components/pepper-cash-modal" */).then(c => wrapFunctional(c.default || c))
export const PostsPostList = () => import('../../components/Posts/PostList.vue' /* webpackChunkName: "components/posts-post-list" */).then(c => wrapFunctional(c.default || c))
export const PostsPostPreview = () => import('../../components/Posts/PostPreview.vue' /* webpackChunkName: "components/posts-post-preview" */).then(c => wrapFunctional(c.default || c))
export const SearchCategories = () => import('../../components/Search/Categories.vue' /* webpackChunkName: "components/search-categories" */).then(c => wrapFunctional(c.default || c))
export const SearchCities = () => import('../../components/Search/Cities.vue' /* webpackChunkName: "components/search-cities" */).then(c => wrapFunctional(c.default || c))
export const SearchDotsLoading = () => import('../../components/Search/DotsLoading.vue' /* webpackChunkName: "components/search-dots-loading" */).then(c => wrapFunctional(c.default || c))
export const SearchEvents = () => import('../../components/Search/Events.vue' /* webpackChunkName: "components/search-events" */).then(c => wrapFunctional(c.default || c))
export const SearchPerformer = () => import('../../components/Search/Performer.vue' /* webpackChunkName: "components/search-performer" */).then(c => wrapFunctional(c.default || c))
export const SearchPerformers = () => import('../../components/Search/Performers.vue' /* webpackChunkName: "components/search-performers" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/Search/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SearchNearme = () => import('../../components/Search/SearchNearme.vue' /* webpackChunkName: "components/search-nearme" */).then(c => wrapFunctional(c.default || c))
export const SearchPageEvent = () => import('../../components/SearchPage/Event.vue' /* webpackChunkName: "components/search-page-event" */).then(c => wrapFunctional(c.default || c))
export const SearchPageEvents = () => import('../../components/SearchPage/Events.vue' /* webpackChunkName: "components/search-page-events" */).then(c => wrapFunctional(c.default || c))
export const SearchPageNoResults = () => import('../../components/SearchPage/NoResults.vue' /* webpackChunkName: "components/search-page-no-results" */).then(c => wrapFunctional(c.default || c))
export const SearchPagePerformer = () => import('../../components/SearchPage/Performer.vue' /* webpackChunkName: "components/search-page-performer" */).then(c => wrapFunctional(c.default || c))
export const SearchPagePerformers = () => import('../../components/SearchPage/Performers.vue' /* webpackChunkName: "components/search-page-performers" */).then(c => wrapFunctional(c.default || c))
export const SeatsCheckoutButton = () => import('../../components/Seats/CheckoutButton.vue' /* webpackChunkName: "components/seats-checkout-button" */).then(c => wrapFunctional(c.default || c))
export const SeatsInfo = () => import('../../components/Seats/Info.vue' /* webpackChunkName: "components/seats-info" */).then(c => wrapFunctional(c.default || c))
export const SeatsPriceDetail = () => import('../../components/Seats/PriceDetail.vue' /* webpackChunkName: "components/seats-price-detail" */).then(c => wrapFunctional(c.default || c))
export const SeatsSelectSplit = () => import('../../components/Seats/SelectSplit.vue' /* webpackChunkName: "components/seats-select-split" */).then(c => wrapFunctional(c.default || c))
export const SeatsTicketSelector = () => import('../../components/Seats/TicketSelector.vue' /* webpackChunkName: "components/seats-ticket-selector" */).then(c => wrapFunctional(c.default || c))
export const ShareButton = () => import('../../components/Share/Button.vue' /* webpackChunkName: "components/share-button" */).then(c => wrapFunctional(c.default || c))
export const SignInForm = () => import('../../components/SignIn/Form.vue' /* webpackChunkName: "components/sign-in-form" */).then(c => wrapFunctional(c.default || c))
export const SplashIntroSplash = () => import('../../components/Splash/IntroSplash.vue' /* webpackChunkName: "components/splash-intro-splash" */).then(c => wrapFunctional(c.default || c))
export const SplashIntroSplashHwi = () => import('../../components/Splash/IntroSplashHwi.vue' /* webpackChunkName: "components/splash-intro-splash-hwi" */).then(c => wrapFunctional(c.default || c))
export const SpotifyLoginButton = () => import('../../components/Spotify/LoginButton.vue' /* webpackChunkName: "components/spotify-login-button" */).then(c => wrapFunctional(c.default || c))
export const SpotifySigninButton = () => import('../../components/Spotify/SigninButton.vue' /* webpackChunkName: "components/spotify-signin-button" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionEnjoyVIPPriceModal = () => import('../../components/Subscription/EnjoyVIPPriceModal.vue' /* webpackChunkName: "components/subscription-enjoy-v-i-p-price-modal" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionJoinGroupModal = () => import('../../components/Subscription/JoinGroupModal.vue' /* webpackChunkName: "components/subscription-join-group-modal" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionReviewSubscription = () => import('../../components/Subscription/ReviewSubscription.vue' /* webpackChunkName: "components/subscription-review-subscription" */).then(c => wrapFunctional(c.default || c))
export const TevoCarousel = () => import('../../components/Tevo/Carousel.vue' /* webpackChunkName: "components/tevo-carousel" */).then(c => wrapFunctional(c.default || c))
export const TevoEventsArray = () => import('../../components/Tevo/EventsArray.vue' /* webpackChunkName: "components/tevo-events-array" */).then(c => wrapFunctional(c.default || c))
export const ToastInfoToast = () => import('../../components/Toast/InfoToast.vue' /* webpackChunkName: "components/toast-info-toast" */).then(c => wrapFunctional(c.default || c))
export const ToastPromoToast = () => import('../../components/Toast/PromoToast.vue' /* webpackChunkName: "components/toast-promo-toast" */).then(c => wrapFunctional(c.default || c))
export const ToastPromoToastApplied = () => import('../../components/Toast/PromoToastApplied.vue' /* webpackChunkName: "components/toast-promo-toast-applied" */).then(c => wrapFunctional(c.default || c))
export const ToastPromoToastAppliedNearme = () => import('../../components/Toast/PromoToastAppliedNearme.vue' /* webpackChunkName: "components/toast-promo-toast-applied-nearme" */).then(c => wrapFunctional(c.default || c))
export const ToastPromoToastContact = () => import('../../components/Toast/PromoToastContact.vue' /* webpackChunkName: "components/toast-promo-toast-contact" */).then(c => wrapFunctional(c.default || c))
export const UIAppButton = () => import('../../components/UI/AppButton.vue' /* webpackChunkName: "components/u-i-app-button" */).then(c => wrapFunctional(c.default || c))
export const UIAppControlInput = () => import('../../components/UI/AppControlInput.vue' /* webpackChunkName: "components/u-i-app-control-input" */).then(c => wrapFunctional(c.default || c))
export const UserArtistSelectModal = () => import('../../components/User/ArtistSelectModal.vue' /* webpackChunkName: "components/user-artist-select-modal" */).then(c => wrapFunctional(c.default || c))
export const UserInviteFriends = () => import('../../components/User/InviteFriends.vue' /* webpackChunkName: "components/user-invite-friends" */).then(c => wrapFunctional(c.default || c))
export const UserProfileMenu = () => import('../../components/User/ProfileMenu.vue' /* webpackChunkName: "components/user-profile-menu" */).then(c => wrapFunctional(c.default || c))
export const UserPromoRegister = () => import('../../components/User/PromoRegister.vue' /* webpackChunkName: "components/user-promo-register" */).then(c => wrapFunctional(c.default || c))
export const UserPromoSignIn = () => import('../../components/User/PromoSignIn.vue' /* webpackChunkName: "components/user-promo-sign-in" */).then(c => wrapFunctional(c.default || c))
export const UserResetPassWord = () => import('../../components/User/ResetPassWord.vue' /* webpackChunkName: "components/user-reset-pass-word" */).then(c => wrapFunctional(c.default || c))
export const UserSelectedTicketPromoSection = () => import('../../components/User/SelectedTicketPromoSection.vue' /* webpackChunkName: "components/user-selected-ticket-promo-section" */).then(c => wrapFunctional(c.default || c))
export const UserSignIn = () => import('../../components/User/SignIn.vue' /* webpackChunkName: "components/user-sign-in" */).then(c => wrapFunctional(c.default || c))
export const UserSignInOnboarding = () => import('../../components/User/SignInOnboarding.vue' /* webpackChunkName: "components/user-sign-in-onboarding" */).then(c => wrapFunctional(c.default || c))
export const UserSignInPromoSection = () => import('../../components/User/SignInPromoSection.vue' /* webpackChunkName: "components/user-sign-in-promo-section" */).then(c => wrapFunctional(c.default || c))
export const UserSignInPromoSectionBefore = () => import('../../components/User/SignInPromoSectionBefore.vue' /* webpackChunkName: "components/user-sign-in-promo-section-before" */).then(c => wrapFunctional(c.default || c))
export const UserSignUp = () => import('../../components/User/SignUp.vue' /* webpackChunkName: "components/user-sign-up" */).then(c => wrapFunctional(c.default || c))
export const IconsIcBaselineArrowBack = () => import('../../components/icons/IcBaselineArrowBack.vue' /* webpackChunkName: "components/icons-ic-baseline-arrow-back" */).then(c => wrapFunctional(c.default || c))
export const IconsMaterialSymbolsInfo = () => import('../../components/icons/MaterialSymbolsInfo.vue' /* webpackChunkName: "components/icons-material-symbols-info" */).then(c => wrapFunctional(c.default || c))
export const IconsMdiArrowLeft = () => import('../../components/icons/MdiArrowLeft.vue' /* webpackChunkName: "components/icons-mdi-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const IconsMdiCheckboxMarkedCircle = () => import('../../components/icons/MdiCheckboxMarkedCircle.vue' /* webpackChunkName: "components/icons-mdi-checkbox-marked-circle" */).then(c => wrapFunctional(c.default || c))
export const IconsMdiCircle = () => import('../../components/icons/MdiCircle.vue' /* webpackChunkName: "components/icons-mdi-circle" */).then(c => wrapFunctional(c.default || c))
export const IconsMdiClose = () => import('../../components/icons/MdiClose.vue' /* webpackChunkName: "components/icons-mdi-close" */).then(c => wrapFunctional(c.default || c))
export const IconsMdiCloseCircleOutline = () => import('../../components/icons/MdiCloseCircleOutline.vue' /* webpackChunkName: "components/icons-mdi-close-circle-outline" */).then(c => wrapFunctional(c.default || c))
export const IconsMdiFacebook = () => import('../../components/icons/MdiFacebook.vue' /* webpackChunkName: "components/icons-mdi-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconsMdiFilterMenu = () => import('../../components/icons/MdiFilterMenu.vue' /* webpackChunkName: "components/icons-mdi-filter-menu" */).then(c => wrapFunctional(c.default || c))
export const IconsMdiInformation = () => import('../../components/icons/MdiInformation.vue' /* webpackChunkName: "components/icons-mdi-information" */).then(c => wrapFunctional(c.default || c))
export const IconsMdiLinkVariant = () => import('../../components/icons/MdiLinkVariant.vue' /* webpackChunkName: "components/icons-mdi-link-variant" */).then(c => wrapFunctional(c.default || c))
export const IconsMdiMinusCircleOutline = () => import('../../components/icons/MdiMinusCircleOutline.vue' /* webpackChunkName: "components/icons-mdi-minus-circle-outline" */).then(c => wrapFunctional(c.default || c))
export const IconsMdiPlusCircleOutline = () => import('../../components/icons/MdiPlusCircleOutline.vue' /* webpackChunkName: "components/icons-mdi-plus-circle-outline" */).then(c => wrapFunctional(c.default || c))
export const IconsMdiShieldCheck = () => import('../../components/icons/MdiShieldCheck.vue' /* webpackChunkName: "components/icons-mdi-shield-check" */).then(c => wrapFunctional(c.default || c))
export const IconsMdiWhatsapp = () => import('../../components/icons/MdiWhatsapp.vue' /* webpackChunkName: "components/icons-mdi-whatsapp" */).then(c => wrapFunctional(c.default || c))
export const IconsPepperCheckoutMarkedCircle = () => import('../../components/icons/PepperCheckoutMarkedCircle.vue' /* webpackChunkName: "components/icons-pepper-checkout-marked-circle" */).then(c => wrapFunctional(c.default || c))
export const IconsPepperCloseCircleOutline = () => import('../../components/icons/PepperCloseCircleOutline.vue' /* webpackChunkName: "components/icons-pepper-close-circle-outline" */).then(c => wrapFunctional(c.default || c))
export const ImageObserver = () => import('../../components/imageObserver/imageObserver.vue' /* webpackChunkName: "components/image-observer" */).then(c => wrapFunctional(c.default || c))
export const Mql = () => import('../../components/mql/Mql.vue' /* webpackChunkName: "components/mql" */).then(c => wrapFunctional(c.default || c))
export const ShareLinkButton = () => import('../../components/Share/Link/Button.vue' /* webpackChunkName: "components/share-link-button" */).then(c => wrapFunctional(c.default || c))
export const ShareMessengerButton = () => import('../../components/Share/Messenger/Button.vue' /* webpackChunkName: "components/share-messenger-button" */).then(c => wrapFunctional(c.default || c))
export const ShareModalSocial = () => import('../../components/Share/Modal/Social.vue' /* webpackChunkName: "components/share-modal-social" */).then(c => wrapFunctional(c.default || c))
export const ShareModalView = () => import('../../components/Share/Modal/View.vue' /* webpackChunkName: "components/share-modal-view" */).then(c => wrapFunctional(c.default || c))
export const ShareWhatsAppButton = () => import('../../components/Share/WhatsApp/Button.vue' /* webpackChunkName: "components/share-whats-app-button" */).then(c => wrapFunctional(c.default || c))
export const UserPromoAdded = () => import('../../components/User/Promo/Added.vue' /* webpackChunkName: "components/user-promo-added" */).then(c => wrapFunctional(c.default || c))
export const UserPromoBenefits = () => import('../../components/User/Promo/Benefits.vue' /* webpackChunkName: "components/user-promo-benefits" */).then(c => wrapFunctional(c.default || c))
export const UserPromoRegisterBanner = () => import('../../components/User/Promo/RegisterBanner.vue' /* webpackChunkName: "components/user-promo-register-banner" */).then(c => wrapFunctional(c.default || c))
export const UserPromoSkiped = () => import('../../components/User/Promo/Skiped.vue' /* webpackChunkName: "components/user-promo-skiped" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
